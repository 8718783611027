(function ($, Drupal) {
  'use strict';

  const COOKIE_NAME = 'tf-lead-source';
  const LEAD_SOURCE_FIELD_NAME = 'field_lead_source[und][0][value]';
  const REFERRING_URL_FIELD_NAME = 'field_referring_url[und][0][value]';
  const CAMPAIGN_FIELD_NAME = 'field_campaign[und][0][value]';

  let parseLeadSource = function (context) {
    let urlData = {
      'currentUrl': window.location.href,
      'referrer': document.referrer,
    };

    $.post('/lead_source', urlData)
        .done(function (data) {
          setReferrerCookie(data);
          populateFields(data, context);
        });
  };

  let populateFields = function (leadSource, context) {
    let $leadSourceInput = $('input[name="' + LEAD_SOURCE_FIELD_NAME + '"]', context);
    let $referringURLInput = $('input[name="' + REFERRING_URL_FIELD_NAME + '"]', context);
    let $campaignInput = $('input[name="' + CAMPAIGN_FIELD_NAME + '"]', context);

    if ($leadSourceInput.length) {
      let leadMediumSource = leadSource.medium
          ? leadSource.medium + ' - ' + leadSource.source
          : 'Unknown Lead Source';

      $leadSourceInput.val(leadMediumSource);
    }

    if ($referringURLInput.length) {
      $referringURLInput.val(leadSource.referringUrl);
    }

    if ($campaignInput.length) {
      $campaignInput.val(leadSource.campaign);
    }
  };

  function getReferrerCookie() {
    let cookie = Cookies.get(COOKIE_NAME);

    if (cookie === undefined || cookie === '') {
      return false;
    }

    return JSON.parse(cookie);
  }

  function setReferrerCookie(referrer) {
    Cookies.set(COOKIE_NAME, JSON.stringify(referrer), {
      expires: 1,
      path: '/',
    });
  }

  Drupal.behaviors.topfloorLeadSource = {
    attach: function (context, settings) {
      let leadSource = getReferrerCookie();

      if (!leadSource) {
        parseLeadSource(context);
      }
      else {
        populateFields(leadSource, context);
      }
    }
  };
}(jQuery, Drupal));
